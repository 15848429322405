html {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

body div {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

article, aside, figure, footer, header, nav, section, details, summary {
  display: block;
}

html {
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
}

*:before, *:after {
  box-sizing: inherit;
}

img, object, embed {
  max-width: 100%;
}

html {
  overflow-y: scroll;
}

ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  white-space: pre-line;
  word-wrap: break-word;
}

input[type="radio"] {
  vertical-align: text-bottom;
}

input[type="checkbox"] {
  vertical-align: bottom;
}

.ie7 input[type="checkbox"] {
  vertical-align: baseline;
}

.ie6 input {
  vertical-align: text-bottom;
}

select, input, textarea {
  font: 99% sans-serif;
}

table {
  font-size: inherit;
  font: 100%;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

td {
  vertical-align: top;
}

td img {
  vertical-align: top;
}

sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

pre, code, kbd, samp {
  font-family: monospace, sans-serif;
}

.clickable, label {
  cursor: pointer;
}

input[type=button], input[type=submit], input[type=file] {
  cursor: pointer;
}

button {
  cursor: pointer;
  margin: 0;
}

input, select, textarea {
  margin: 0;
}

button, input[type=button] {
  width: auto;
  overflow: visible;
}

.ie7 img {
  -ms-interpolation-mode: bicubic;
}

.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

@font-face {
  font-family: AkhandSoft;
  src: url(../fonts/AkhandSoft/AkhandSoft-Regular.otf);
}

@font-face {
  font-family: AkhandSoft-Light;
  src: url(../fonts/AkhandSoft/AkhandSoft-Light.otf);
}

@font-face {
  font-family: AkhandSoft-Extrabold;
  src: url(../fonts/AkhandSoft/AkhandSoft-Extrabold.otf);
}

@font-face {
  font-family: AkhandSoft-Semibold;
  src: url(../fonts/AkhandSoft/AkhandSoft-Semibold.otf);
}

@font-face {
  font-family: Tw-Cen-MT;
  src: url(../fonts/Tw/Tw-Cen-MT.TTF);
}

@font-face {
  font-family: Tw-Cen-MT-Bold;
  src: url(../fonts/Tw/Tw-Cen-MT-Bold.TTF);
}

@font-face {
  font-family: Tw-Cen-MT-Italic;
  src: url(../fonts/Tw/Tw-Cen-MT-Italic.TTF);
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  font-family: 'Tw-Cen-MT';
  color: #000000;
  line-height: 1.5;
  background-color: #ffefe4;
}

.ct-container {
  padding: 0 70px;
  width: 100%;
}

@media (max-width: 1440px) {
  .ct-container {
    padding: 0 52px;
  }
}

@media (max-width: 1199px) {
  .ct-container {
    padding: 0 44px;
  }
}

@media (max-width: 991px) {
  .ct-container {
    padding: 0;
  }
}

.ct-row {
  margin: 0 -32px;
}

@media (max-width: 1440px) {
  .ct-row {
    margin: 0 -24px;
  }
}

@media (max-width: 1199px) {
  .ct-row {
    margin: 0 -20px;
  }
}

@media (max-width: 991px) {
  .ct-row {
    margin: 0;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.hide {
  display: none !important;
}

html {
  overflow: auto;
}

.fullpage {
  display: block;
  width: 100%;
  float: left;
  overflow: hidden;
}

a {
  -webkit-transition: all, 0.3s;
  -khtml-transition: all, 0.3s;
  -moz-transition: all, 0.3s;
  -ms-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
  text-decoration: none;
}

a:focus, a:active, a:hover, a:hover:focus, a:hover:active, a:hover:hover {
  text-decoration: none;
}

input, button, select, textarea {
  -webkit-transition: all, 0.5s;
  -khtml-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

input:hover, input:active, input:focus, button:hover, button:active, button:focus, select:hover, select:active, select:focus, textarea:hover, textarea:active, textarea:focus {
  outline: none;
}

button {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  -webkit-transition: all, 0.3s;
  -khtml-transition: all, 0.3s;
  -moz-transition: all, 0.3s;
  -ms-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

img, iframe {
  vertical-align: middle;
}

main {
  clear: both;
}

strong span {
  font-weight: bold;
}

.flex, .flex-wrap {
  clear: both;
}

.justify-content-xl-center {
  display: flex;
  clear: both;
  flex-wrap: wrap;
}

@media (min-width: 1200px) {
  .justify-content-xl-center {
    justify-content: center;
  }
}

@media (max-width: 1199px) {
  .justify-content-lg-center {
    display: flex;
    clear: both;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width: 991px) {
  .pc {
    display: none !important;
  }
}

.mobi {
  display: none !important;
}

@media (max-width: 991px) {
  .mobi {
    display: block !important;
  }
}

.vertical-scrolling {
  overflow: hidden;
}

.fp-tableCell {
  display: block;
}

.flex-section .fp-tableCell {
  display: flex;
  flex-wrap: wrap;
}

.flex-section .fp-tableCell .flex-item {
  float: left;
  width: 100%;
}

.flex-section-center .fp-tableCell {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.flex-section-center .fp-tableCell .flex-item {
  float: left;
  width: 100%;
}

#fp-nav {
  display: none;
}

.section-between .fp-tableCell {
  align-content: space-between;
}

.header .ct-header {
  padding-bottom: 45px;
}

.header .main-header {
  display: flex;
  flex-wrap: no-wrap;
  align-items: flex-end;
  padding-bottom: 25px;
  margin-bottom: 30px;
  border-bottom: 4px solid #000000;
}

@media (max-width: 1760px) {
  .header .main-header {
    padding-bottom: calc(25px * 0.92);
    margin-bottom: calc(30px * 0.92);
    border-bottom: calc(4px * 0.92) solid #000000;
  }
}

@media (max-width: 1600px) {
  .header .main-header {
    padding-bottom: calc(25px * 0.83);
    margin-bottom: calc(30px * 0.83);
    border-bottom: calc(4px * 0.83) solid #000000;
  }
}

@media (max-width: 1440px) {
  .header .main-header {
    padding-bottom: calc(25px * 0.75);
    margin-bottom: calc(30px * 0.75);
    border-bottom: calc(4px * 0.75) solid #000000;
  }
}

@media (max-width: 1366px) {
  .header .main-header {
    padding-bottom: calc(25px * 0.72);
    margin-bottom: calc(30px * 0.72);
    border-bottom: calc(4px * 0.72) solid #000000;
  }
}

@media (max-width: 1280px) {
  .header .main-header {
    padding-bottom: calc(25px * 0.67);
    margin-bottom: calc(30px * 0.67);
    border-bottom: calc(4px * 0.67) solid #000000;
  }
}

@media (max-width: 1199px) {
  .header .main-header {
    padding-bottom: calc(25px * 0.51);
    margin-bottom: calc(30px * 0.51);
  }
}

@media (max-width: 767px) {
  .header .main-header {
    padding-bottom: calc(25px * 0.28);
    margin-bottom: calc(30px * 0.28);
  }
}

@media (max-width: 543px) {
  .header .main-header {
    flex-wrap: wrap;
    border-bottom: 2px solid #000000;
  }
}

.header .main-header .logo {
  width: 140px;
  float: left;
  padding-top: 70px;
  padding-left: 25px;
}

@media (max-width: 1760px) {
  .header .main-header .logo {
    width: calc(140px * 0.92);
    padding-top: calc(70px * 0.92);
    padding-left: calc(25px * 0.92);
  }
}

@media (max-width: 1600px) {
  .header .main-header .logo {
    width: calc(140px * 0.83);
    padding-top: calc(70px * 0.83);
    padding-left: calc(25px * 0.83);
  }
}

@media (max-width: 1440px) {
  .header .main-header .logo {
    padding-top: calc(70px * 0.75);
    padding-left: calc(25px * 0.75);
  }
}

@media (max-width: 1366px) {
  .header .main-header .logo {
    padding-top: calc(70px * 0.72);
    padding-left: calc(25px * 0.72);
  }
}

@media (max-width: 1280px) {
  .header .main-header .logo {
    padding-top: calc(70px * 0.67);
    padding-left: calc(25px * 0.67);
  }
}

@media (max-width: 1199px) {
  .header .main-header .logo {
    width: calc(140px * 0.51);
    padding-top: calc(70px * 0.51);
    padding-left: calc(25px * 0.51);
  }
}

@media (max-width: 543px) {
  .header .main-header .logo {
    width: 100%;
    text-align: center;
    padding-top: calc(70px * 0.17);
    padding-left: calc(25px * 0.17);
  }
  .header .main-header .logo img {
    width: 80px;
  }
}

.header .main-header .main-menu {
  width: calc(100% - 140px);
  float: left;
}

@media (max-width: 1760px) {
  .header .main-header .main-menu {
    width: calc(100% - (140px * 0.92));
  }
}

@media (max-width: 1600px) {
  .header .main-header .main-menu {
    width: calc(100% - (140px * 0.83));
  }
}

@media (max-width: 1440px) {
  .header .main-header .main-menu {
    width: calc(100% - (140px * 0.75));
  }
}

@media (max-width: 1366px) {
  .header .main-header .main-menu {
    width: calc(100% - (140px * 0.72));
  }
}

@media (max-width: 1280px) {
  .header .main-header .main-menu {
    width: calc(100% - (140px * 0.67));
  }
}

@media (max-width: 1199px) {
  .header .main-header .main-menu {
    width: calc(100% - (140px * 0.51));
  }
}

@media (max-width: 543px) {
  .header .main-header .main-menu {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.header .main-header .main-menu .menu {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 1.6875rem;
  line-height: 1;
}

@media (max-width: 1760px) {
  .header .main-header .main-menu .menu {
    font-size: 1.5525rem;
  }
}

@media (max-width: 1600px) {
  .header .main-header .main-menu .menu {
    font-size: 1.400625rem;
  }
}

@media (max-width: 1440px) {
  .header .main-header .main-menu .menu {
    font-size: 1.265625;
  }
}

@media (max-width: 1366px) {
  .header .main-header .main-menu .menu {
    font-size: 1.215rem;
  }
}

@media (max-width: 1280px) {
  .header .main-header .main-menu .menu {
    font-size: 1.130625rem;
  }
}

@media (max-width: 543px) {
  .header .main-header .main-menu .menu {
    justify-content: space-between;
    font-size: 0.9375rem;
  }
}

.header .main-header .main-menu .menu li {
  float: left;
  padding: 10px 40px 0;
}

@media (max-width: 1760px) {
  .header .main-header .main-menu .menu li {
    padding-left: 36px;
    padding-right: 36px;
  }
}

@media (max-width: 1600px) {
  .header .main-header .main-menu .menu li {
    padding-left: 33px;
    padding-right: 33px;
  }
}

@media (max-width: 1440px) {
  .header .main-header .main-menu .menu li {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 1366px) {
  .header .main-header .main-menu .menu li {
    padding-left: 28px;
    padding-right: 28px;
  }
}

@media (max-width: 1280px) {
  .header .main-header .main-menu .menu li {
    padding-left: 26px;
    padding-right: 26px;
  }
}

@media (max-width: 1199px) {
  .header .main-header .main-menu .menu li {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 767px) {
  .header .main-header .main-menu .menu li {
    padding-left: 11px;
    padding-right: 11px;
    padding-top: 15px;
  }
  .header .main-header .main-menu .menu li:first-child {
    padding-left: 0;
  }
  .header .main-header .main-menu .menu li:last-child {
    padding-right: 0;
  }
}

.header .main-header .main-menu .menu li a {
  color: #000000;
  cursor: pointer;
}

.footer {
  background-color: #000;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding: 25px 0px 20px;
  font-size: 1.125rem;
  color: #efc9af;
}

@media (max-width: 1760px) {
  .footer {
    font-size: 1.035rem;
  }
}

@media (max-width: 1600px) {
  .footer {
    font-size: 0.93375rem;
  }
}

@media (max-width: 1440px) {
  .footer {
    font-size: 0.84375rem;
  }
}

@media (max-width: 1366px) {
  .footer {
    font-size: 0.81rem;
  }
}

@media (max-width: 1280px) {
  .footer {
    font-size: 0.75375rem;
  }
}

.footer ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1199px) {
  .footer ul {
    flex-wrap: wrap;
    text-align: center;
  }
}

@media (max-width: 1199px) {
  .footer ul li {
    width: 100%;
  }
}

@-webkit-keyframes rotation {
  10% {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  }
  50%, 60% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  90% {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  }
  100% {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  }
}

@keyframes rotation {
  10% {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  }
  50%, 60% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  90% {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  }
  100% {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  }
}

#orientLayer {
  display: none;
}

@media screen and (max-width: 1199px) and (min-aspect-ratio: 13 / 8) {
  #orientLayer {
    display: block;
  }
}

.mod-orient-layer {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #000;
  z-index: 9997;
}

.mod-orient-layer__content {
  position: absolute;
  width: 100%;
  top: 45%;
  margin-top: -75px;
  text-align: center;
}

.mod-orient-layer__icon-orient {
  background-image: url("../img/download.png");
  display: inline-block;
  width: 67px;
  height: 109px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -webkit-animation: rotation infinite 1.5s ease-in-out;
  animation: rotation infinite 1.5s ease-in-out;
  -webkit-background-size: 67px;
  background-size: 67px;
}

.mod-orient-layer__desc {
  text-shadow: none;
  margin-top: 20px;
  font-size: 15px;
  color: #fff;
}

.breadcrumb {
  background-color: transparent;
  padding: 0;
  line-height: 1;
  font-size: 1.125rem;
  margin: 0;
}

@media (max-width: 1760px) {
  .breadcrumb {
    font-size: 1.035rem;
  }
}

@media (max-width: 1600px) {
  .breadcrumb {
    font-size: 0.93375rem;
  }
}

@media (max-width: 1199px) {
  .breadcrumb {
    font-size: 0.81rem;
  }
}

.breadcrumb:after {
  display: none;
}

.breadcrumb ul {
  padding: 0;
  display: flex;
  flex-wrap: no-wrap;
}

.breadcrumb ul li {
  padding-right: 25px;
  position: relative;
}

@media (max-width: 991px) {
  .breadcrumb ul li {
    padding-right: 10px;
  }
}

.breadcrumb ul li::after {
  content: "\f105";
  font-family: FontAwesome;
  padding-left: 25px;
}

@media (max-width: 991px) {
  .breadcrumb ul li::after {
    padding-left: 10px;
  }
}

.breadcrumb ul li:last-child::after {
  content: '';
}

.breadcrumb ul li a {
  color: #000000;
}

.product .title-group {
  font-family: Tw-Cen-MT-Bold;
  font-size: 2.5rem;
  line-height: 1;
  padding: 25px 0;
}

@media (max-width: 1760px) {
  .product .title-group {
    font-size: 2.3rem;
    padding: calc(30px * 0.92) 0;
  }
}

@media (max-width: 1600px) {
  .product .title-group {
    font-size: 2.075rem;
    padding: calc(30px * 0.83) 0;
  }
}

@media (max-width: 1440px) {
  .product .title-group {
    font-size: 1.875rem;
    padding: calc(30px * 0.75) 0;
  }
}

@media (max-width: 1366px) {
  .product .title-group {
    font-size: 1.8rem;
    padding: calc(30px * 0.72) 0;
  }
}

@media (max-width: 1280px) {
  .product .title-group {
    font-size: 1.675rem;
    padding: calc(30px * 0.67) 0;
  }
}

.product .group-detail {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .product .group-detail {
    padding: 0 15px;
  }
}

@media (max-width: 991px) {
  .product .group-farm {
    padding: 0 15px;
  }
}

.product .group-farm .des-farm {
  font-size: 1.5625vw;
  padding: 2.2395vw;
}

@media (max-width: 767px) {
  .product .group-farm .des-farm {
    font-size: 1.97vw;
  }
}

.product .group-farm .farm {
  padding: 40px 72px;
  border: 5px solid #cd8a70;
  font-size: 1.875rem;
}

@media (max-width: 1760px) {
  .product .group-farm .farm {
    font-size: 1.725rem;
    padding: calc(40px * 0.92) calc(72px * 0.92);
  }
}

@media (max-width: 1600px) {
  .product .group-farm .farm {
    font-size: 1.55625rem;
    padding: calc(40px * 0.83) calc(72px * 0.83);
  }
}

@media (max-width: 1440px) {
  .product .group-farm .farm {
    font-size: 1.40625rem;
    padding: calc(40px * 0.75) calc(72px * 0.75);
  }
}

@media (max-width: 1366px) {
  .product .group-farm .farm {
    font-size: 1.35rem;
    padding: calc(40px * 0.72) calc(72px * 0.72);
  }
}

@media (max-width: 1280px) {
  .product .group-farm .farm {
    font-size: 1.25625rem;
    padding: calc(40px * 0.67) calc(72px * 0.67);
  }
}

@media (max-width: 1199px) {
  .product .group-farm .farm {
    font-size: 0.95625rem;
    padding: calc(40px * 0.51) calc(72px * 0.51);
  }
}

.product .group-farm .farm .info-farm {
  font-family: Tw-Cen-MT-Bold;
}

.product .group-farm .farm .des-farm {
  font-family: Tw-Cen-MT-Italic;
  margin-top: 40px;
}

@media (max-width: 1760px) {
  .product .group-farm .farm .des-farm {
    margin-top: calc(40px * 0.92);
  }
}

@media (max-width: 1600px) {
  .product .group-farm .farm .des-farm {
    margin-top: calc(40px * 0.83);
  }
}

@media (max-width: 1440px) {
  .product .group-farm .farm .des-farm {
    margin-top: calc(40px * 0.75);
  }
}

@media (max-width: 1366px) {
  .product .group-farm .farm .des-farm {
    margin-top: calc(40px * 0.72);
  }
}

@media (max-width: 1280px) {
  .product .group-farm .farm .des-farm {
    margin-top: calc(40px * 0.67);
  }
}
